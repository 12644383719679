<template>
  <div v-if="product">
    <form id="product-item" @submit.prevent="submit">
      <b-card v-if="product">
        <b-form-group>
          <label for="title">
            عنوان
            <i
              class="fas fa-asterisk text-danger"
              style="font-size: 0.5rem;"
            ></i
          ></label>
          <b-form-input
            id="title"
            name="title"
            v-model="product.title"
            data-required
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <label for="product_code"> کد محصول </label>
          <b-form-input
            id="product_code"
            name="product_code"
            v-model="product.product_code"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="توضیحات کوتاه" label-for="description">
          <textarea
            id="description"
            class="form-control"
            name="description"
            v-model="product.description"
          ></textarea>
        </b-form-group>

        <b-form-group label="توضیحات کامل" label-for="body">
          <textarea id="body" class="form-control" v-model="body"></textarea>
        </b-form-group>

        <div class="row">
          <div class="col-md-4">
            <b-form-group>
              <label for="image">
                تصویر اصلی
                <i
                  class="fas fa-asterisk text-danger"
                  style="font-size: 0.5rem;"
                ></i
              ></label>
              <b-form-file
                id="image"
                name="image"
                v-model="product.file"
              ></b-form-file>
            </b-form-group>
          </div>

          <div class="col-md-4">
            <b-form-group label="ویدئو" label-for="video">
              <b-form-file id="video" name="video"></b-form-file>
            </b-form-group>
          </div>
          <div class="col-md-4">
            <b-form-group>
              <label for="status">
                وضعیت
                <i
                  class="fas fa-asterisk text-danger"
                  style="font-size: 0.5rem;"
                ></i
              ></label>
              <b-form-select
                id="status"
                name="status"
                v-if="!$root.isEmptyObject(product)"
                v-model="product.status"
                data-required
              >
                <b-form-select-option
                  value="available"
                  style="font-family: 'Vazir';"
                  >موجود
                </b-form-select-option>
                <b-form-select-option
                  value="unavailable"
                  style="font-family: 'Vazir';"
                  >ناموجود
                </b-form-select-option>
                <b-form-select-option
                  value="inactive"
                  style="font-family: 'Vazir';"
                  >غیرفعال
                </b-form-select-option>
                <b-form-select-option value="soon" style="font-family: 'Vazir';"
                  >به زودی
                </b-form-select-option>
              </b-form-select>
              <b-form-select id="status" name="status" v-else data-required>
                <b-form-select-option
                  value="available"
                  style="font-family: 'Vazir';"
                  >موجود
                </b-form-select-option>
                <b-form-select-option
                  value="unavailable"
                  style="font-family: 'Vazir';"
                  >ناموجود
                </b-form-select-option>
                <b-form-select-option
                  value="inactive"
                  style="font-family: 'Vazir';"
                  >غیرفعال
                </b-form-select-option>
                <b-form-select-option value="soon" style="font-family: 'Vazir';"
                  >به زودی
                </b-form-select-option>
              </b-form-select>
            </b-form-group>
          </div>
          <div
            v-if="categories && categories.length"
            class="col-md-4 categoryBox"
          >
            <label for="">
              دسته بندی :
            </label>
            <div dir="rtl">
              <treeselect
                :options="categories"
                v-model="product.selected_category"
                placeholder="انتخاب دسته بندی "
                :alwaysOpen="false"
                :normalizer="normalizer"
                :clearable="true"
                :multiple="false"
                valueFormat="object"
                class="rtl"
              ></treeselect>
            </div>
          </div>
        </div>
      </b-card>
      <div
        v-if="product && product.show_attributes"
        class="row attr"
        :disabled="show_overlay"
      >
        <div class="col-md-8">
          <b-card title="ویژگی‌های محصول">
            <b-table-simple striped bordered v-if="product.attributes != ''">
              <b-tbody>
                <b-tr
                  v-for="attribute in product.attributes"
                  :key="attribute.id"
                >
                  <b-td>{{ attribute.name }}</b-td>
                  <b-td style="width: 50%">
                    <div
                      v-if="
                        attribute.type == 'multi_select' && attribute.contents
                      "
                    >
                      <multiselect
                        class="form-group"
                        :options="attribute.contents"
                        label="value"
                        track-by="value"
                        v-model="attribute.selected_attribute"
                        placeholder=""
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                        multiple
                      >
                      </multiselect>
                      <input
                        type="hidden"
                        :name="'attributes[' + attribute.id + '][values][]'"
                        v-for="item in attribute.selected_attribute"
                        :key="item.value"
                        :value="item.value"
                      />
                    </div>
                    <div
                      v-else-if="
                        attribute.type == 'select' && attribute.contents
                      "
                    >
                      <multiselect
                        class="form-group"
                        :options="attribute.contents"
                        label="value"
                        track-by="id"
                        v-model="attribute.selected_attribute"
                        placeholder=""
                        :searchable="true"
                        :close-on-select="true"
                        :show-labels="false"
                      >
                      </multiselect>
                      <input
                        type="hidden"
                        :name="'attributes[' + attribute.id + '][values][]'"
                        :value="attribute.selected_attribute.value"
                        v-if="attribute.selected_attribute"
                      />
                    </div>
                    <div v-else-if="attribute.type == 'text'">
                      <input
                        type="text"
                        class="form-control"
                        v-model="attribute.selected_attribute.value"
                      />
                      <input
                        type="hidden"
                        :name="'attributes[' + attribute.id + '][values][]'"
                        :value="attribute.selected_attribute.value"
                        v-if="attribute.selected_attribute"
                      />
                    </div>
                    <div v-else-if="attribute.type == 'textarea'">
                      <textarea
                        type="text"
                        class="form-control"
                        rows="0"
                        v-model="attribute.selected_attribute.value"
                      ></textarea>
                      <input
                        type="hidden"
                        :name="'attributes[' + attribute.id + '][values][]'"
                        :value="attribute.selected_attribute.value"
                        v-if="attribute.selected_attribute"
                      />
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-card>
        </div>
        <div v-if="product" class="col-md-4">
          <b-card title="برند">
            <multiselect
              class="form-group"
              :options="product.brands"
              label="name"
              track-by="id"
              v-model="product.brand"
              placeholder=""
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
            ></multiselect>
            <input
              type="hidden"
              name="brand_id"
              :value="product.brand.id"
              v-if="product.brand"
            />
          </b-card>
        </div>
      </div>
      <div class="clearfix mt-2">
        <button
          class="btn btn-primary float-right"
          v-if="$route.params.id"
          :disabled="disabled"
        >
          بروز رسانی
        </button>
        <button class="btn btn-success float-right" v-else :disabled="disabled">
          ثبت
        </button>
      </div>
      <input type="hidden" name="_method" value="PUT" v-if="$route.params.id" />
    </form>
  </div>
</template>
<script>
// import CategoriesList from "../category/Categories";
import { Treeselect } from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
export default {
  props: ["product", "params"],
  components: {
    // CategoriesList,
    Treeselect,
  },
  data() {
    return {
      categories: [],
      date: "",
      url: "/api/admin/products",
      ckeditorLoaded: false,
      disabled: false,
      body: "",
      attributes: [],
      urlAttribute: "",
      selected_category: null,
      brands: [],
      show_overlay: false,
      disabledCategory: false,
      counter: 0,
    };
  },
  mounted() {

    this.loadCategories();
    this.requesrProductEdit();

    if (this.counter < 1) {
      this.requesrProductEdit();
      // this.checkRoute();
      this.counter++;

    }
  },
  methods: {
    requesrProductEdit() {
      if (this.$route.params.product == "edit" && this.$route.params.id) {
        this.$axios
          .get(
            this.$root.baseUrl + "/api/admin/products/" + this.$route.params.id
          )
          .then((response) => {
            this.body = response.data.data.product.body;
          
            this.product.selected_category =
              response.data.data.product.category;
            this.loadCkeditor();

          });
      } else {
        this.loadCkeditor();
      }
    },
    loadOrginalAttributes(id) {
      this.$axios
        .get(
          `https://api.albamarket.ir/api/categories-attributes?category_id=${id}`
        )
        .then((res) => {
          this.setAttributes(res.data.data);
        });
    },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
      };
    },

    loadCategories() {
      this.$axios
        .get(this.$root.baseUrl + "/api/admin/categories?all=true")
        .then((response) => {
          this.categories = response.data.data;
        });
    },
    async loadCkeditor() {
      // if (!this.ckeditorLoaded) {
      //   this.ckeditorLoaded = true;
      //   let script = document.createElement("script");
      //   script.src = "/editors/ckeditor/ckeditor.js";
      //   window.$("head").append(script);
      // }
      // setTimeout(() => {
      //   window.CKEDITOR.replace("body");
      // }, 0);
      if (!this.ckeditorLoaded) {
        this.ckeditorLoaded = true;
        let script = document.createElement("script");
        script.src = "/editors/ckeditor/ckeditor.js";
        document.head.appendChild(script);

        await new Promise((resolve) => {
          script.onload = () => {
            resolve();
          };
        });
      }
      this.$nextTick(() => {
        setTimeout(() => {
          window.CKEDITOR.replace("body");
        }, 0);
      });
    },

    setAttributes(attributes) {
      const allAttributes = window.clone(attributes);
      const pickedAttributes = window.clone(this.product.attributes);
      const x = {};
      this.$props.product.show_attributes = true;
     
      allAttributes.forEach((item) => {
        this.$root.$set(
          item,
          "selected_attribute",
          x[item.id]
            ? x[item.id]
            : item.type == "textarea" || item.type == "text"
            ? {
                value: "",
              }
            : []
        );
      });
      this.$props.product.attributes = allAttributes;
    },
    submit() {
      if (this.$route.params.id) {
        this.itemEdited();
      } else {
        this.newItem();
      }
    },
    newItem() {
      this.disabled = true;
      let form = document.getElementById("product-item");
      let formData = new FormData(form);
      formData.append("category_id", this.product.selected_category.id);
      const body = window.CKEDITOR.instances.body.getData();
      formData.append("body", body);
      this.$axios
        .post(this.$root.baseUrl + this.url, formData)
        .then((response) => {
          this.$root.success_notification(response.data.message);
          this.$emit("nextStep", response.data.data.product);
          this.$props.product.id = response.data.data.product.id;
          this.disabledCategory = true;
        })
        .catch((error) => {
          this.$root.error_notification(error);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
    itemEdited() {
      this.disabled = true;
      let form = document.getElementById("product-item");
      let formData = new FormData(form);
      formData.append(
        "category_id",
        this.product.selected_category.id
          ? this.product.selected_category.id
          : this.product.selected_category
      );
      const body = window.CKEDITOR.instances.body.getData();
      formData.append("body", body);
      this.$axios
        .post(this.$root.baseUrl + this.url + `/${this.product.id}`, formData)
        .then((response) => {
          this.$root.success_notification(response.data.message);
          // this.$props.product.title = response.data.data.product.title
          // this.$props.product.description = response.data.data.product.description
          this.$props.product.category = response.data.data.product.category;
        })
        .catch((error) => {
          this.$root.error_notification(error);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
   
  },

  watch: {
    "product.selected_category": {
      handler(newItem) {
        // اطمینان از اینکه newItem معتبر است
        if (newItem) {
          if (!this.$route.fullPath.includes("edit")) {
            this.product.brands = newItem.brands || []; // از یک آرایه خالی استفاده کنید اگر brands وجود ندارد
            this.loadOrginalAttributes(newItem.id);
          } else {
            if (newItem.attributes) this.setAttributes(newItem.attributes);
          }
        } else {
          // اگر newItem برابر با null است، برندها را خالی کنید
          this.product.brands = [];
        }
      },
      deep: true,
    },
 
  },
};
</script>
<style>
.attr .card {
  height: 100% !important;
}
</style>
