<template>
  <div>
    <b-overlay :show="show" no-wrap fixed z-index="9999">
      <template v-slot:overlay>
        <div class="d-flex align-items-center">
          <b-spinner small type="grow" variant="dark" class="mx-1"></b-spinner>
          <b-spinner type="grow" variant="dark" class="mx-1"></b-spinner>
          <b-spinner small type="grow" variant="dark" class="mx-1"></b-spinner>
        </div>
      </template>
    </b-overlay>
    <template v-if="product">

    <form-wizard
      ref="wizard"
      @on-complete="onComplete"
      @on-change="checkTab"
      shape="tab"
      back-button-text="مرحله قبل"
      next-button-text="مرحله بعد"
      finish-button-text="پایان"
      finish-button-color="red"
      color="rgb(26, 180, 187)"
    >
      <h2 slot="title"></h2>
      <tab-content title="ثبت محصول" :before-change="beforeTabSwitch">
        <step-1
          :params="$route.params.product"
          :product="product"
          @nextStep="goToStep2"
        >
        </step-1>
      </tab-content>
      <tab-content title="تنوع ها">
        <step-2 :product="product"></step-2>
      </tab-content>
      <!-- <tab-content title="ثبت محصول" :before-change="beforeTabSwitch">
        <step-1 @nextStep="goToStep2" :product="product.product ? product.product : {}"></step-1>
      </tab-content>
      <tab-content title="ویژگی‌ها">
        <step-2 @nextStep="goToStep2" :product="product" v-if="product.product"></step-2>
      </tab-content>
      <tab-content title="تنوع‌ها">
        <step-3
          @nextStep="goToStep2"
          @pushToVarieties="pushToVarieties"
          @manageVarietyQuantity="manageVarietyQuantity"
          :product="product"
          v-if="product.product"
        ></step-3>
      </tab-content>
      <tab-content title="گالری تصاویر">
        <step-4 @nextStep="goToStep2" @rootVarieties="rootVarieties" @pushToVarietyGalleries="pushToVarietyGalleries" :product="product" v-if="product.product"></step-4>
      </tab-content> -->
    </form-wizard>
  </template>

  </div>
</template>

<script>
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { FormWizard, TabContent } from "vue-form-wizard";

import Step1 from "./step1";
import Step2 from "./step2";

export default {
  components: {
    FormWizard,
    Step1,
    Step2,
    TabContent,
  },
  data() {
    return {
      url: "/api/admin/products",
      product: {},
      search: {},
      sort: {},
      loading: false,
      attributes: [],
      title: "ثبت محصول",
      varieties: [],
      show: false,
      newProduct: false,
    };
  },
  methods: {
    checkTab(prevIndex, nextIndex) {
      if (prevIndex == 0 && nextIndex == 1 && this.newProduct == false) {
        if (
          this.product.title == "" ||
          this.product.selected_category == null ||
          this.product.status == "" ||
          this.product.file == ""
        ) {
          setTimeout(() => {
            this.$refs.wizard.navigateToTab(0);
          }, 1);
        } else if (
          this.product.selected_category != "" &&
          this.product.attributes != ""
        ) {
          this.product.attributes.map((item) =>
            item.required == 1 &&
            item.selected_attribute == "" &&
            (item.type == "multi_select" || item.type == "select")
              ? (this.$root.warning_notification(
                  " فیلد " + item.name + " الزامی است "
                ),
                setTimeout(() => {
                  this.$refs.wizard.navigateToTab(0);
                }, 1))
              : item.required == 1 &&
                item.selected_attribute.value == "" &&
                (item.type == "textarea" || item.type == "text")
              ? (this.$root.warning_notification(
                  " فیلد " + item.name + " الزامی است "
                ),
                setTimeout(() => {
                  this.$refs.wizard.navigateToTab(0);
                }, 1))
              : ""
          );
        }
        if (this.newProduct == false) {
          this.$root.warning_notification("محصول را ثبت کنید");
          setTimeout(() => {
            this.$refs.wizard.navigateToTab(0);
          }, 1);
        }
        if (this.product.title == "") {
          this.$root.warning_notification("فیلد عنوان الزامی است");
        }
        if (this.product.selected_category == null) {
          this.$root.warning_notification("فیلد دسته بندی الزامی است");
        }
        if (this.product.status == "") {
          this.$root.warning_notification("فیلد وضعیت الزامی است");
        }
        if (this.product.file == "") {
          this.$root.warning_notification("فیلد تصویر اصلی الزامی است");
        }
      }
    },
    onComplete() {
      this.$router.push({
        name: "products",
      });
    },
    beforeTabSwitch() {
      window.$(window).scrollTop(0);
      return true;
    },
    goToStep2(e) {
      console.log("e", e);
      this.product = e;
      this.$refs.wizard.navigateToTab(1);
      this.newProduct = true;
    },
    // findVarietyIndex (varieties, id) {
    //   for (const index in varieties) {
    //     if (varieties[index].id === parseInt(id)) {
    //       return index
    //     }
    //   }
    // },
    // pushToVarieties (data) {
    //   this.product.product.varieties.unshift(data)
    //   window.$.get('/api/admin/list/varieties/gallery/' + this.product.id, response => {
    //       if (response.success) {
    //         this.varieties = response.data
    //       }
    //     })
    // },
    manageVarietyQuantity(data) {
      const index = this.findVarietyIndex(
        this.product.product.varieties,
        data.variety_id
      );
      const variety = this.product.product.varieties[index];
      var quantity = data.variety.in_storage;
      // if (data.type === 'decrement') {
      //   quantity = parseInt(variety.in_storage) - parseInt(data.quantity)
      // } else {
      //   quantity = parseInt(variety.in_storage) + parseInt(data.quantity)
      // }

      this.$root.$set(variety, "in_storage", quantity);
    },
    pushToVarietyGalleries(data) {
      const varieties = data.varieties;
      delete data.varieties;

      varieties.forEach((item) => {
        const index = this.findVarietyIndex(
          this.product.product.varieties,
          item.id
        );
        this.product.product.varieties[index].galleries.push(data);
      });

      this.success_notification("تصویر به تنوع‌ها اضافه شد");
    },
    rootVarieties() {
      return this.varieties;
    },
    getProduct(id) {
      this.title = "ویرایش محصول";
      this.show = true;
      this.$axios
        .get(this.$root.baseUrl + "/api/admin/products/" + id)
        .then((response) => {
          this.product = {
            id: response.data.data.product.id,
            title: response.data.data.product.title,
            description: response.data.data.product.description,
            category: response.data.data.category,
            body: response.data.data.product.body,
            status: response.data.data.product.status,
            category_id: response.data.data.category.id,
            file: [],
            show_attributes: false,
            selected_category: {
              id: response.data.data.product.category.id,
              label: response.data.data.product.category.name,
            },
            brand: response.data.data.product.brand,
            brands: response.data.data.brands,
            attributes: [],
            varieties: response.data.data.product.varieties,
            product_code: response.data.data.product.product_code,
          };
          this.setAttributes(
            response.data.data.attributes,
            response.data.data.product
          );
          this.newProduct = true;
          this.show = false;
        });
    },
    setAttributes(attributes, product) {
      let notFound = [];
      attributes.map((el) =>
        product.attributes.map((item) =>
          item.id == el.id ? (item.contents = el.contents) : ""
        )
      );
      product.attributes.forEach((pa) => {
        let finded = attributes.find((a) => a.id == pa.id);
        if (!finded) notFound.push(pa);
      });
      const allAttributes = window.clone(attributes);
      allAttributes.push(...notFound);
      const pickedAttributes = window.clone(product.attributes);
      const x = {};

      if (this.$route.params.id) {
        pickedAttributes.forEach((picked) => {
          if (picked.type === "select" || picked.type === "multi_select") {
            if (!x[picked.pivot.attribute_id]) {
              x[picked.pivot.attribute_id] = [];
            }

            this.$root.$set(picked.pivot, "id", picked.pivot.attribute_id);
            x[picked.pivot.attribute_id].push(picked.pivot);
          } else {
            x[picked.pivot.attribute_id] = picked.pivot;
          }
        });
      }

      allAttributes.forEach((item) => {
        this.$root.$set(
          item,
          "selected_attribute",
          x[item.id]
            ? x[item.id]
            : item.type == "textarea" || item.type == "text"
            ? {
                value: "",
              }
            : []
        );
      });
      this.product.attributes = allAttributes;
      this.product.show_attributes = true;
    },
    getAttributes() {
      this.$axios.get("/api/admin/attribute?page=1").then((res) => {
        this.setAttributes(res.data.data.data, this.product);
      });
    },
  },
  mounted() {
    if (this.$route.params.product == "edit" && this.$route.params.id) {
      this.getProduct(this.$route.params.id);
    } else {
      // this.getAttributes();
      this.product = {
        id: "",
        title: "",
        description: "",
        category: "",
        body: "",
        status: "",
        category_id: 0,
        file: [],
        show_attributes: false,
        selected_category: null,
        brands: [],
        brand: "",
        attributes: [],
        varieties: [],
        product_code: "",
      };
    }
    document.title = this.title;
  },
};
</script>
